import * as React from "react"
import Header from "../components/Header"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"
import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
    <>
      <Helmet title="JouLumme Joulukalenteri" />
      <Header />
      <main>
        <h1>
          404
          <br />
          Sivua ei löydy
        </h1>
        <p>
          <Link to="/">Etusivulle</Link>.
        </p>
      </main>
      <Footer />
    </>
  )
}

export default NotFoundPage
